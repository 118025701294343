<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">合格证管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编码"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
             <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                clearable
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                filterable
                size="small"
              ></el-cascader>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width:100%"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>

            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
                @cell-click="dblclick"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="120"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="150"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeName"
              />
              <el-table-column
                label="开班时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
              />
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
              />
              <el-table-column
                label="完成人数"
                align="left"
                show-overflow-tooltip
                prop="graduationNum"
              />
              <el-table-column
                label="培训状态"
                align="left"
                show-overflow-tooltip
                prop="trainState"
              />
              <el-table-column
                label="证书编号起始号"
                align="left"
                show-overflow-tooltip
                prop="numberPrefix"
              />
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                prop="occupationName"
              />
              <el-table-column
                label="操作"
                align="center"
                width="170px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="
                      downStudentDetails(scope.row.projectId, scope.row.userId)
                    "
                    :disabled="scope.row.studyProgress == 10"
                    >下载学员明细</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="
                      downcertificate(scope.row.projectId, scope.row.userId)
                    "
                    :disabled="scope.row.studyProgress == 10"
                    >下载证书</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="查看学时证明"
      :visible.sync="dialogCert"
      width="50%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 800px"></div>
    </el-dialog>
    <!-- 证书编号起始号 -->
    <el-dialog
      title="证书编号"
      :visible.sync="certficateDialog"
      width="50%"
      center
    >
      <div >
           <el-form
          label-position="right"
          class="demo-table-expand"
          label-width="100px"
          :model="dialogData"
          ref="dialogData"
        >
        <el-form-item label="证书编号" prop="certficateNo">
            <el-input
              placeholder="请输入证书编号"
              v-model="dialogData.certficateNo"
            />
          </el-form-item>
           </el-form>
            <div style="display: flex; justify-content: center; margin-top: 15px">
          <el-button @click="doCloseCertficateDialog()">取消</el-button>
          <el-button
            class="bgc-bv"
            @click="submitForm('dialogData')"
            >确认</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "set/creditHoursStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectCode: "", //班级编号
      projectName: "", //班级名称
      compId: "", //机构名称
      areaId:'', // 行政区划
      dialogCert: false,
      certficateDialog:false,
      companyList: [],
      dialogData:{},
       areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
    };
  },
   computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
      this.getareatree()
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      this.doFetch({
        url: "/biz/project/certificate/page",
        params,
        pageNum,
      });
    },
    /* 行政区划 */
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    //起始号点数输入
    dblclick(row, column, cell, event) {
        
      switch (column.label) {
        case "证书编号起始号":
            if(!row.numberPrefix) {
               this.certficateDialog = true
               this.projectId = row.projectId
         } else {
            //   console.log('hhhff');
         }
          break;
      }
    },
    //确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/biz/project/certificate/number/add", {
            projectId: this.projectId,
            numberPrefix: this.dialogData.certficateNo,
          }).then((ret) => {
            if (ret.status == 0) {
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.certficateDialog = false;
              this.dialogData = {};
              this.getData(-1);
            }
          });
        }
      });
    },
    doCloseCertficateDialog() {
        this.certficateDialog = false;
        this.dialogData = {}
    },
    //下载学员明细
    downStudentDetails(projectId) {
      let parma = {
        projectId: projectId,
      };
      this.$post("/biz/project/certificate/download", parma).then((res) => {
         if (res.status == "0") {
          let list = res.data;
          this.editPopupDialogVisible = false;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //下载学员明细
    downcertificate(projectId) {
      let parma = {
        projectId: projectId,
      };
      this.$post("/biz/project/certificate/downloadClassCertificate", parma).then((res) => {
         if (res.status == "0") {
          let list = res.data;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    /* 所属企业 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyFJList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    getTableHeight(opDom = true, page = true, tr = 40) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // this.pageSize = Math.ceil(this.tableHeight/tr)
    },
  },
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
